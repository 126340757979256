var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.product.isVirtual)?_c('BaseButton',{attrs:{"variant":_vm.variant,"icon-button":"","icon":"pfm-arrow-right","to":_vm.localePath(
            _vm.product.url
                ? {
                    name: 'product-detail-slug',
                    params: { slug: _vm.product.url },
                }
                : {
                    name: 'index',
                }
            )}}):_c('BaseButton',{attrs:{"icon-visible":false,"size":"lg","variant":_vm.variant},on:{"click":function($event){return _vm.onAddToCart(_vm.product.id)}}},[_vm._t("default",function(){return [_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }