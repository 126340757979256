var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative bg-white w-full sm:p-8 grid grid-cols-1 sm:grid-cols-12 sm:gap-x-8"},[_c('div',{staticClass:"card__image max-h-[27rem] max-w-[27rem] my-8 col-span-12 sm:col-span-6 md:col-span-5 lg:col-span-4 xl:col-span-3 relative bg-white rounded-2xl h-60 sm:h-auto mx-auto flex justify-center items-center"},[_c('NuxtLink',{staticClass:"absolute inset-0 z-10",attrs:{"to":_vm.localePath(
          _vm.product.url
            ? {
                name: 'product-detail-slug',
                params: { slug: _vm.product.url },
                query: _vm.query,
              }
            : {
                name: 'index',
              }
        )},nativeOn:{"click":function($event){return _vm.onClickProduct.apply(null, arguments)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.product.name))])]),_vm._v(" "),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.product.img+'?w=960&h=960'),expression:"product.img+'?w=960&h=960'"}],staticClass:"mx-auto h-full w-full object-contain rounded-2xl",attrs:{"alt":_vm.product.name}}),_vm._v(" "),(_vm.product.label)?_c('div',{staticClass:"absolute top-0 right-0 z-20",class:_vm.product.discounts && _vm.product.discounts.length
          ? 'max-w-1/2'
          : 'max-w-4/5'},[_c('ProductLabel',{attrs:{"label":_vm.product.label}})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"prose prose-headings:font-light font-weight:lighter prose-headings:mt-3 prose-headings:mb-2 max-w-full col-span-12 sm:col-span-6 md:col-span-7 lg:col-span-8 xl:col-span-9 flex items-center"},[_c('div',{staticClass:"flex flex-col justify-start border-2 rounded-2xl p-8 max-w-full",class:_vm.themeBorderClass},[(_vm.title)?[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})]:[_c('h3',{staticClass:"lowercase font-bold text-pfm-black-400 text-xl min-h-9 mb-0.5"},[_c('span',{staticClass:"first-letter:uppercase block"},[_vm._v(_vm._s(_vm.product.name))])]),_vm._v(" "),(_vm.product.description)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.description)}}):_vm._e()],_vm._v(" "),_c('div',{staticClass:"min-h-10 flex items-center justify-start mt-8"},[_c('BaseButton',{staticClass:"mr-4 font-light",attrs:{"label":"Lees meer","to":_vm.localePath(
              _vm.product.url
                ? {
                    name: 'product-detail-slug',
                    params: { slug: _vm.product.url },
                    query: _vm.query,
                  }
                : {
                    name: 'index',
                  }
            ),"size":"lg","variant":"primary","icon-visible":false}}),_vm._v(" "),(_vm.product)?_c('ProductAddToCartButton',{attrs:{"product":_vm.product,"variant":_vm.theme}},[_vm._v("\n          Kopen\n        ")]):_vm._e()],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }